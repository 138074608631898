<template>
  <v-container fluid class="px-1 py-0">
    <v-card class="border-radius-xl mt-3 pb-2">
      <div class="d-flex">
        <div style="width: calc(100% - 550px)">
          <FirmTable></FirmTable>
        </div>
        <div style="width: 500px">
          <div>123123</div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import FirmTable from "@/components/Firm/FirmTable.vue";

export default {
  name: "Rotation",
  components: { FirmTable },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    Bus.$on("adminTabChange", (e) => {
      this.currentTab = e;
    });
  },
  methods: {},
};
</script>
